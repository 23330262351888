import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [fixed, setFixed] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setScrolled(scrollY > 1);
    setFixed(scrollY > 72);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div
          className={`header__inner ${fixed ? "fixed" : ""} ${
            scrolled ? "scrolled" : ""
          }`}
        >
          <div className="wrp-navi">
            <div className="fixed_txt_img">
              <img
                width="146"
                height="40"
                src="	https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/corp_header-logo-b.png"
                alt="サイバーコム株式会社"
              />
            </div>
            <ul>
              <li>
                <Link to="/" onClick={toTop}>
                  家
                </Link>
              </li>

              <li>
                <Link to="/privacy" onClick={toTop}>
                  プライバシーポリシー
                </Link>
              </li>
              <li>
                <Link to="/news" onClick={toTop}>
                  お知らせ
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toTop}>
                  お問い合わせ
                </Link>
              </li>
              <li>
                <Link to="/download" onClick={toTop}>
                  資料ダウンロード
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
