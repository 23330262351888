import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Privacy() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="contact_main">
        <div className="contact_top">
          <div className="contact_top_inner">
            <div className="contact_top_body">
              <div class="l-subpage-ttl-block__jp">プライバシーポリシー</div>
              <div class="l-subpage-ttl-block__en">Privacy</div>
            </div>
          </div>
        </div>

        <div className="privacy_box">
          <div className="privacy_box_inner">
            <div class="p_privacy-cont__ttl">
              <div class="c-ttl01">個人情報保護方針</div>
            </div>
            <p>
              株式会社GeNEE（以下、「当社」とする。）は、お客様の個人情報保護の重要性を強く認識し、またお客様との信頼関係を今後も恒久的に築いていくため、以下の通り、プライバシーポリシーを定め、お客様の個人情報の適切な保護に努めます。
            </p>
            <div class="p_privacy-cont__ttl">
              <div class="c-ttl01">個人情報の取得について</div>
            </div>
            <p>
              当社は、偽りその他不正の手段によらず、適正に個人情報を取得致します。
            </p>
            <div class="p_privacy-cont__ttl">
              <div class="c-ttl01">個人情報の内容について</div>
            </div>
            <p>
              本プライバシーポリシー内の個人情報とは、当社コーポレートサイト内でお客様にご入力して頂く、氏名、電話番号、メールアドレス、その他ご記述頂いた情報など、個人を特定できる情報を指します。
            </p>
            <div class="p_privacy-cont__ttl">
              <div class="c-ttl01">個人情報の利用について</div>
            </div>
            <p>
              当社は、個人情報を以下の利用目的の達成に必要な範囲内で利用致します。以下に定めのない目的で個人情報を利用する場合、予めご本人様の同意を得た上で行います。
              ・トラブル解決のため
              ・当社サービスをよりご満足頂けるよう改良するため
            </p>
            <div class="p_privacy-cont__ttl">
              <div class="c-ttl01">個人情報の安全管理について</div>
            </div>
            <p>
              当社は、取り扱う個人情報の漏洩、滅失または毀損を防止し、安全管理のための措置を適切に実施致します。
            </p>
            <div class="p_privacy-cont__ttl">
              <div class="c-ttl01">個人情報の委託について</div>
            </div>
            <p>
              当社は、個人情報の取り扱いの全部または一部を第三者に委託する場合、当該第三者について厳正な調査を実施し、取り扱いを委託された個人情報の安全管理が適切に図られるよう、当該第三者に対する必要かつ適切な監督を実施致します。
            </p>
            <div class="p_privacy-cont__ttl">
              <div class="c-ttl01">個人情報の第三者提供について</div>
            </div>
            <p>
              当社は、個人情報保護法等の法令に定めのある場合を除き、個人情報を予めご本人様の同意を得ることなく、第三者に提供致しません。
            </p>
            <div class="p_privacy-cont__ttl">
              <div class="c-ttl01">情報の開示・訂正等について</div>
            </div>
            <p>
              当社は、ご本人様から自己の個人情報についての開示の請求がある場合、速やかに開示を致します。その際、ご本人様であることが確認できない場合には、開示に応じません。個人情報の内容に誤りがあり、ご本人様から訂正・追加・削除の請求がある場合、当社側で調査を実施した上、速やかにこれらの請求に対応致します。その際、ご本人様であることが確認できない場合には、これらの請求に応じません。
              当社の個人情報の取り扱いに関して、上記の請求・お問い合わせ等がございましたら、下記までご連絡頂けますよう宜しくお願い申し上げます。
            </p>
            <div class="p_privacy-cont__ttl">
              <div class="c-ttl01">連絡先</div>
            </div>
            <p>
              株式会社GeNEE（英語表記：GeNEE Corporation）
              <br />
              メールアドレス：super@yuyo.blog
              <br />
              電話番号　　　：(415) 425-9345
              <br />
              営業時間　　　：平日10時～18時（土日祝日は休業）
              <br />
              代表取締役社長：日向野 卓也
              <br />
              本方針の変更について
              <br />
              本方針の内容は変更される場合がございます。変更後の方針に関しましては、当社が別途定める場合を除き、当サイトに掲載したときから効力が発生するものとします。
            </p>
          </div>
        </div>
      </div>
      <div className="c-cta-block">
        <Link
          to="/contact"
          className="c-cta-block__item contact"
          onClick={toTop}
        >
          <div className="c-cta-block__ico">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/ico-contact-wt.svg"
              alt=""
            />
          </div>
          <div className="c-cta-block__main-ttl">お問い合わせ</div>
          <div className="c-cta-block__sub-ttl">Contact</div>
          <div className="c-cta-block__caption">
            DXコンサルティング、スマホアプリ開発、 システム開発に
            <br className="pc" />
            関する ご相談はこちらから
          </div>
          <div className="c-cta-block__arrow">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/arrow-wt-bt.svg"
              alt=""
            />
          </div>
        </Link>
        <Link
          to="/download"
          className="c-cta-block__item download"
          onClick={toTop}
        >
          <div className="c-cta-block__ico">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/ico-download-wt.svg"
              alt=""
            />
          </div>
          <div className="c-cta-block__main-ttl">資料ダウンロード</div>
          <div className="c-cta-block__sub-ttl">Download</div>
          <div className="c-cta-block__caption download">
            GeNEEの資料についてダウンロードはこちらから
          </div>
          <div className="c-cta-block__arrow">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/arrow-wt-bt.svg"
              alt=""
            />
          </div>
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
