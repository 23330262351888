import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="foot_body">
          <div className="foot_internal">
            <div className="footer-logo-box">
              <Link to="/" className="footer-logo-box__logo" onClick={toTop}>
                <img
                  src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/corp_header-logo-b.png"
                  alt="株式会社GeNEE"
                />
              </Link>
              <div className="footer-logo-box__ttl">株式会社GeNEE</div>
              <div className="footer-logo-box__address">
                〒1309
                <br />
                Coffeen Avenue STE 1200
                <br />
                Sheridan, Wyoming 82801
              </div>
            </div>

            <div className="footer-nav-box">
              <div className="footer-nav-cont">
                <div className="footer-nav-cont__box">
                  <div className="footer-nav-cont__item">
                    <a href="#home-company" className="footer-nav-cont__ttl">
                      会社概要
                    </a>
                  </div>
                  <div className="footer-nav-cont__subttl-box">
                    <a href="#home-vision" className="footer-nav-cont__subttl">
                      ビジョン
                    </a>
                    <a href="#home-mission" className="footer-nav-cont__subttl">
                      ミッション
                    </a>
                    <a href="#home-value" className="footer-nav-cont__subttl">
                      提供価値
                    </a>
                    <a href="#home-company" className="footer-nav-cont__subttl">
                      会社概要
                    </a>
                    <a
                      href="#home-leadership"
                      className="footer-nav-cont__subttl"
                    >
                      経営陣
                    </a>

                    <a href="/" className="footer-nav-cont__subttl">
                      沿革
                    </a>
                    <a href="/" className="footer-nav-cont__subttl">
                      社長挨拶
                    </a>
                  </div>
                </div>
                <div className="footer-nav-cont__box">
                  <div className="footer-nav-cont__item">
                    <a href="/" className="footer-nav-cont__ttl">
                      事業内容
                    </a>
                  </div>
                  <div className="footer-nav-cont__subttl-box">
                    <a href="/" className="footer-nav-cont__subttl">
                      システム開発
                    </a>
                    <a href="/" className="footer-nav-cont__subttl">
                      スマホアプリ開発
                    </a>
                    <a href="/" className="footer-nav-cont__subttl">
                      DXコンサルティング
                    </a>

                    <a href="/" className="footer-nav-cont__subttl">
                      MVP開発
                    </a>
                    <a href="/" className="footer-nav-cont__subttl">
                      AI開発
                    </a>
                    <a href="/" className="footer-nav-cont__subttl">
                      脆弱性診断
                    </a>
                  </div>
                </div>

                <div className="footer-nav-cont__box">
                  <div className="footer-nav-cont__item">
                    <a href="/" className="footer-nav-cont__ttl">
                      製品・サービス
                    </a>
                  </div>
                  <div className="footer-nav-cont__subttl-box">
                    <a href="/" className="footer-nav-cont__subttl">
                      SaaS系勤怠管理
                    </a>
                  </div>
                </div>
                <div className="footer-nav-cont__box sp-flex">
                  <div className="footer-nav-cont__item">
                    <a href="/" className="footer-nav-cont__ttl">
                      特徴
                    </a>
                  </div>
                  <div className="footer-nav-cont__item">
                    <a href="/" className="footer-nav-cont__ttl">
                      開発実績
                    </a>
                  </div>
                  <div className="footer-nav-cont__item">
                    <a href="/" className="footer-nav-cont__ttl">
                      お客様の声
                    </a>
                  </div>
                  <div className="footer-nav-cont__item">
                    <a href="/" className="footer-nav-cont__ttl">
                      お知らせ
                    </a>
                  </div>
                  <div className="footer-nav-cont__item">
                    <a href="/" className="footer-nav-cont__ttl">
                      メディア
                    </a>
                  </div>
                  <div className="footer-nav-cont__item">
                    <a href="/" className="footer-nav-cont__ttl">
                      ウェビナー
                    </a>
                  </div>
                  <div className="footer-nav-cont__item">
                    <a href="/" className="footer-nav-cont__ttl">
                      採用情報
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer-btn-wrap">
                <div className="footer-btn-box">
                  <div className="footer-btn-box__item">
                    <Link to="/contact" className="c-btn01" onClick={toTop}>
                      お問い合わせ
                    </Link>
                  </div>
                  <div className="footer-btn-box__item">
                    <Link
                      to="/download"
                      className="c-btn01 ptn-blown"
                      onClick={toTop}
                    >
                      資料ダウンロード
                    </Link>
                  </div>
                </div>
                <a href="tel:03-4500-8256" className="footer-tel-box">
                  <div className="footer-tel-box__ico">
                    <img
                      src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/ico-tel.svg"
                      alt=""
                    />
                  </div>
                  <div className="footer-tel-box__num">03-4500-8256</div>
                </a>
              </div>
            </div>
          </div>
          <div className="footer-bottom-block">
            <div className="footer-bottom-block__culture">
              <a href="/">GeNEEの8つのカルチャー</a>
            </div>
            <div className="footer-bottom-block__privacy">
              <Link to="/privacy" onClick={toTop}>
                プライバシーポリシー
              </Link>
            </div>
            <div className="copyright-block">
              <div className="txt">
                ©GeNEE Corporation 2017−2024 All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
