import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import "../css/news.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function News() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="contact_main">
        <div className="contact_top">
          <div className="contact_top_inner">
            <div className="contact_top_body">
              <div class="l-subpage-ttl-block__jp">お知らせ</div>
              <div class="l-subpage-ttl-block__en">NEWS</div>
            </div>
          </div>
        </div>
        <div className="news_box">
          <ul style={{ width: "850px" }}>
            <li>
              <a href="#">
                <div class="c-blog-list01__imgbox">
                  <div class="c-blog-list01__img">
                    <img src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/09/『アプリ開発発注担当者必見！　新規プロダクト事業を成功させる100の鉄則』アイキャッチ画像-1.png" />
                  </div>
                </div>
                <div class="c-blog-list01__cont">
                  <div class="c-blog-list01__tagbox">
                    <div class="c-blog-list01__tag">
                      <ul class="c-tag-list01">
                        <li>
                          <div class="c-tag-list01__item">ﾌﾟﾚｽﾘﾘｰｽ </div>
                        </li>
                      </ul>
                    </div>
                    <div class="c-blog-list01__date">2024.09.11</div>
                  </div>
                  <div class="c-blog-list01__ttl">
                    『アプリ開発発注担当者必見！　新規プロダクト事業を成功させる100の鉄則』を商業出版しました{" "}
                  </div>
                  <div class="c-blog-list01__desc">
                    出版会社のゴマブックス株式会社様（東京都渋谷区）からオファーをいただき、『アプリ開発発注担当者必見！　新規プロダクト事業…{" "}
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#" class="c-blog-list01__item">
                <div class="c-blog-list01__imgbox">
                  <div class="c-blog-list01__img">
                    <img src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/07/書籍.png" />
                  </div>
                </div>
                <div class="c-blog-list01__cont">
                  <div class="c-blog-list01__tagbox">
                    <div class="c-blog-list01__tag">
                      <ul class="c-tag-list01">
                        <li>
                          <div class="c-tag-list01__item">ﾌﾟﾚｽﾘﾘｰｽ </div>
                        </li>
                      </ul>
                    </div>
                    <div class="c-blog-list01__date">2024.07.12</div>
                  </div>
                  <div class="c-blog-list01__ttl">
                    弊社技術書籍（在庫管理システム）の韓国版を商業出版しました{" "}
                  </div>
                  <div class="c-blog-list01__desc">
                    技術系出版会社の翔泳社様（東京都新宿区）およびSUNG AN DANG
                    INC.（韓国）から商業出版のオファーをいただき…{" "}
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#" class="c-blog-list01__item">
                <div class="c-blog-list01__imgbox">
                  <div class="c-blog-list01__img">
                    <img src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/03/GeNEE_Youtube動画のサムネイル.png" />
                  </div>
                </div>
                <div class="c-blog-list01__cont">
                  <div class="c-blog-list01__tagbox">
                    <div class="c-blog-list01__tag">
                      <ul class="c-tag-list01">
                        <li>
                          <div class="c-tag-list01__item">ニュース </div>
                        </li>
                      </ul>
                    </div>
                    <div class="c-blog-list01__date">2024.03.19</div>
                  </div>
                  <div class="c-blog-list01__ttl">
                    『GeNEEで解決！システム・アプリ開発ch』が開始しました{" "}
                  </div>
                  <div class="c-blog-list01__desc">
                    2024年3月15日（金）、システム開発やアプリ開発のご相談にお応えするYoutube動画、『GeNEEで解決！システム…{" "}
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#" class="c-blog-list01__item">
                <div class="c-blog-list01__imgbox">
                  <div class="c-blog-list01__img">
                    <img src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/02/KYOUSOUプロジェクトPR_タイトル画像B案.jpg" />
                  </div>
                </div>
                <div class="c-blog-list01__cont">
                  <div class="c-blog-list01__tagbox">
                    <div class="c-blog-list01__tag">
                      <ul class="c-tag-list01">
                        <li>
                          <div class="c-tag-list01__item">ﾌﾟﾚｽﾘﾘｰｽ </div>
                        </li>
                      </ul>
                    </div>
                    <div class="c-blog-list01__date">2024.02.09</div>
                  </div>
                  <div class="c-blog-list01__ttl">
                    IT顧問/技術顧問に関するプレスリリースを配信しました{" "}
                  </div>
                  <div class="c-blog-list01__desc">
                    2024年2月9日（金）、PR
                    Wireを通じて「IT顧問/技術顧問」サービスに関するプレスリリースを配信しました。詳細…{" "}
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default News;
