import React, { useState } from "react";
import Footer from "./Footer";
import "../css/contact.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Download() {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    type: "基幹システム/業務システム開発の依頼",
    budget: "",
    situation: "",
    companyName: "",
    departmentName: "",
    name: "",
    mail: "",
    tel: "",
    details: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    window.scrollTo({
      top: 360,
      behavior: "smooth",
    });

    if (!formData.type) errors.type = "必須項目です。";
    if (!formData.companyName) errors.companyName = "未入力です。";
    if (!formData.name) errors.name = "未入力です。";
    if (!formData.mail) errors.mail = "未入力です。";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    alert("送信成功！");
    setFormData({
      type: "基幹システム/業務システム開発の依頼",
      budget: "",
      situation: "",
      companyName: "",
      departmentName: "",
      name: "",
      mail: "",
      tel: "",
      details: "",
    });
    setFormErrors({});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contact_main">
        <div className="contact_top">
          <div className="contact_top_inner">
            <div className="contact_top_body">
              <div class="l-subpage-ttl-block__jp">資料ダウンロード</div>
              <div class="l-subpage-ttl-block__en">Download</div>
            </div>
          </div>
        </div>

        <div className="p_contact-main-block">
          <div className="inner03-block">
            <form>
              <div class="p_contact-top mw_wp_form_confirm-none">
                GeNEE社の会社概要パンフレットはこちらからダウンロードが可能です。
                <br />
                下記の問い合わせフォームにご入力の上、ご依頼ください。
                <br />
                <span style={{ fontSize: "0.7em" }}>
                  ※競合他社様からのご依頼にはお応えしておりません。あらかじめご了承ください。
                </span>
              </div>

              <div className="p_contact-cont">
                <div className="p_contact-table">
                  <table className="c-form-table">
                    <tbody>
                      <tr>
                        <th>
                          <span class="inner">
                            会社名<span>必須</span>
                          </span>
                        </th>
                        <td class="validate-box">
                          <input
                            type="text"
                            name="companyName"
                            class="c-input"
                            size="60"
                            value={formData.companyName}
                            onChange={handleChange}
                          />
                          <br />
                          {formErrors.companyName && (
                            <p className="error">{formErrors.companyName}</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="inner">
                            部署名<span class="any">任意</span>
                          </span>
                        </th>
                        <td class="validate-box">
                          <input
                            type="text"
                            name="departmentName"
                            class="c-input"
                            size="60"
                            value={formData.departmentName}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="inner">
                            お名前<span>必須</span>
                          </span>
                        </th>
                        <td class="validate-box">
                          <input
                            type="text"
                            name="name"
                            class="c-input"
                            size="60"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          <br />
                          {formErrors.name && (
                            <p className="error">{formErrors.name}</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="inner">
                            Eメール<span>必須</span>
                          </span>
                        </th>
                        <td class="validate-box notice">
                          <input
                            type="text"
                            name="mail"
                            class="c-input"
                            size="60"
                            value={formData.mail}
                            onChange={handleChange}
                          />
                          <br />
                          {formErrors.mail && (
                            <p className="error">{formErrors.mail}</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="inner">
                            電話番号<span class="any">任意</span>
                          </span>
                        </th>
                        <td class="validate-box notice">
                          <input
                            type="text"
                            name="tel"
                            class="c-input"
                            size="60"
                            value={formData.tel}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="inner">
                            お問い合わせ種別<span>必須</span>
                          </span>
                        </th>
                        <td class="validate-box notice">
                          <div class="c-select-input">
                            <select
                              name="type"
                              value={formData.type}
                              onChange={handleChange}
                            >
                              <option value="基幹システム/業務システム開発の依頼">
                                基幹システム/業務システム開発の依頼
                              </option>
                              <option value="Webシステム/Webサービス開発の依頼">
                                Webシステム/Webサービス開発の依頼
                              </option>
                              <option value="iOSスマホアプリ開発の依頼">
                                iOSスマホアプリ開発の依頼
                              </option>
                              <option value="Androidスマホアプリ開発の依頼">
                                Androidスマホアプリ開発の依頼
                              </option>
                              <option value="iOS/Androidアプリ開発の依頼">
                                iOS/Androidアプリ開発の依頼
                              </option>
                              <option value="DX推進・サポートの依頼">
                                DX推進・サポートの依頼
                              </option>
                              <option value="MVP開発の依頼">
                                MVP開発の依頼
                              </option>
                              <option value="システム/アプリの保守・運用のご依頼">
                                システム/アプリの保守・運用のご依頼
                              </option>
                              <option value="その他">その他</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="top">
                          <span class="inner">
                            お問い合わせ内容<span class="any">任意</span>
                          </span>
                        </th>
                        <td class="validate-box notice">
                          <textarea
                            name="details"
                            class="c-textarea"
                            cols="50"
                            rows="5"
                            value={formData.details}
                            onChange={handleChange}
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="p_contact-btn-area">
                  <input
                    type="submit"
                    name="submitConfirm"
                    value="入力内容の確認へ"
                    class="c-btn01 medium"
                    onClick={handleFormSubmit}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="c-bread-block outer-block">
          <div class="inner-block">
            <ul class="c-bread-list">
              <li>
                <a href="/" class="icon-home">
                  <img
                    decoding="async"
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon-home.svg"
                    alt=""
                  />
                </a>
              </li>
              <li>お問い合わせ</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="c-cta-block">
        <Link
          to="/contact"
          className="c-cta-block__item contact"
          onClick={toTop}
        >
          <div className="c-cta-block__ico">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/ico-contact-wt.svg"
              alt=""
            />
          </div>
          <div className="c-cta-block__main-ttl">お問い合わせ</div>
          <div className="c-cta-block__sub-ttl">Contact</div>
          <div className="c-cta-block__caption">
            DXコンサルティング、スマホアプリ開発、 システム開発に
            <br className="pc" />
            関する ご相談はこちらから
          </div>
          <div className="c-cta-block__arrow">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/arrow-wt-bt.svg"
              alt=""
            />
          </div>
        </Link>
        <Link
          to="/download"
          className="c-cta-block__item download"
          onClick={toTop}
        >
          <div className="c-cta-block__ico">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/ico-download-wt.svg"
              alt=""
            />
          </div>
          <div className="c-cta-block__main-ttl">資料ダウンロード</div>
          <div className="c-cta-block__sub-ttl">Download</div>
          <div className="c-cta-block__caption download">
            GeNEEの資料についてダウンロードはこちらから
          </div>
          <div className="c-cta-block__arrow">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/arrow-wt-bt.svg"
              alt=""
            />
          </div>
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Download;
