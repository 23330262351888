import React, { useState } from "react";
import Footer from "./Footer";
import "../css/contact.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Contact() {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    type: "",
    budget: "",
    situation: "",
    companyName: "",
    departmentName: "",
    name: "",
    mail: "",
    tel: "",
    details: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    window.scrollTo({
      top: 560,
      behavior: "smooth",
    });

    if (!formData.type) errors.type = "必須項目です。";
    if (!formData.companyName) errors.companyName = "未入力です。";
    if (!formData.name) errors.name = "未入力です。";
    if (!formData.mail) errors.mail = "未入力です。";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    alert("送信成功！");
    setFormData({
      type: "",
      budget: "",
      situation: "",
      companyName: "",
      departmentName: "",
      name: "",
      mail: "",
      tel: "",
      details: "",
    });
    setFormErrors({});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contact_main">
        <div className="contact_top">
          <div className="contact_top_inner">
            <div className="contact_top_body">
              <div class="l-subpage-ttl-block__jp">お問い合わせ</div>
              <div class="l-subpage-ttl-block__en">Contact</div>
            </div>
          </div>
        </div>

        <div className="p_contact-main-block">
          <div className="inner03-block">
            <form>
              <div class="p_contact-top mw_wp_form_confirm-none">
                さまざまな業界業種のお客様向けにDXコンサルティング、システム開発、スマホアプリ開発、新規事業創造のサポートを行っています。下記問い合わせフォームからお気軽にご相談・ご依頼ください。
              </div>
              <div class="p_contact-listbox mw_wp_form_confirm-none">
                <ul class="p_contact-list">
                  <li>
                    <div class="p_contact-list__item">
                      <div class="p_contact-list__circle">
                        <div class="p_contact-list__img">
                          <img
                            decoding="async"
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon-system01.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="p_contact-list__txt">基幹システム開発</div>
                    </div>
                  </li>
                  <li>
                    <div class="p_contact-list__item">
                      <div class="p_contact-list__circle">
                        <div class="p_contact-list__img">
                          <img
                            decoding="async"
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon-web01.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="p_contact-list__txt">業務システム開発</div>
                    </div>
                  </li>
                  <li>
                    <div class="p_contact-list__item">
                      <div class="p_contact-list__circle">
                        <div class="p_contact-list__img">
                          <img
                            decoding="async"
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon-product01.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="p_contact-list__txt">Webシステム開発</div>
                    </div>
                  </li>
                  <li>
                    <div class="p_contact-list__item">
                      <div class="p_contact-list__circle">
                        <div class="p_contact-list__img">
                          <img
                            decoding="async"
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon-it01.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="p_contact-list__txt">Webサービス開発</div>
                    </div>
                  </li>
                  <li>
                    <div class="p_contact-list__item">
                      <div class="p_contact-list__circle">
                        <div class="p_contact-list__img">
                          <img
                            decoding="async"
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon-mobile01.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="p_contact-list__txt">スマホアプリ開発</div>
                    </div>
                  </li>
                  <li>
                    <div class="p_contact-list__item">
                      <div class="p_contact-list__circle">
                        <div class="p_contact-list__img">
                          <img
                            decoding="async"
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon-mvp01.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="p_contact-list__txt">MVP開発</div>
                    </div>
                  </li>
                  <li>
                    <div class="p_contact-list__item">
                      <div class="p_contact-list__circle">
                        <div class="p_contact-list__img">
                          <img
                            decoding="async"
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon-dx01.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="p_contact-list__txt">DX/ITコンサル</div>
                    </div>
                  </li>
                  <li>
                    <div class="p_contact-list__item">
                      <div class="p_contact-list__circle">
                        <div class="p_contact-list__img">
                          <img
                            decoding="async"
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon-ai01.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="p_contact-list__txt">AI開発</div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="p_contact-cont">
                <div className="p_contact-table">
                  <table className="c-form-table">
                    <tbody>
                      <tr>
                        <th>
                          <span class="inner">
                            お問い合わせの種別<span>必須</span>
                          </span>
                        </th>
                        <td className="bifurcation-area">
                          <div class="check-box">
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="基幹システム/業務システム開発の依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type ===
                                    "基幹システム/業務システム開発の依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  基幹システム/業務システム開発の依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="Webシステム/Webサービス開発の依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type ===
                                    "Webシステム/Webサービス開発の依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  Webシステム/Webサービス開発の依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="iOSスマホアプリ開発の依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type ===
                                    "iOSスマホアプリ開発の依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  iOSスマホアプリ開発の依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="Androidスマホアプリ開発の依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type ===
                                    "Androidスマホアプリ開発の依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  Androidスマホアプリ開発の依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="iOS/Androidアプリ開発の依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type ===
                                    "iOS/Androidアプリ開発の依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  iOS/Androidアプリ開発の依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="DX/ITコンサルティングの依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type ===
                                    "DX/ITコンサルティングの依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  DX/ITコンサルティングの依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="MVP開発の依頼"
                                  class="check-btn"
                                  checked={formData.type === "MVP開発の依頼"}
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  MVP開発の依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="システム監査/脆弱性診断の依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type ===
                                    "システム監査/脆弱性診断の依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  システム監査/脆弱性診断の依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="システム/アプリの保守・運用のご依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type ===
                                    "システム/アプリの保守・運用のご依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  システム/アプリの保守・運用のご依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="IT顧問/技術顧問のご相談・ご依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type ===
                                    "IT顧問/技術顧問のご相談・ご依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  IT顧問/技術顧問のご相談・ご依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="ケンスマ勤怠導入のご相談"
                                  class="check-btn"
                                  checked={
                                    formData.type === "ケンスマ勤怠導入のご相談"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  ケンスマ勤怠導入のご相談
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="取材・登壇・寄稿・監修などの依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type ===
                                    "取材・登壇・寄稿・監修などの依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  取材・登壇・寄稿・監修などの依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="定期セミナーの申込の依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type === "定期セミナーの申込の依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  定期セミナーの申込の依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="業務提携・協業の依頼"
                                  class="check-btn"
                                  checked={
                                    formData.type === "業務提携・協業の依頼"
                                  }
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  業務提携・協業の依頼
                                </span>
                              </label>
                            </span>
                            <span class="mwform-radio-field horizontal-item">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  value="その他"
                                  class="check-btn"
                                  checked={formData.type === "その他"}
                                  onChange={handleChange}
                                />
                                <span class="mwform-radio-field-text">
                                  その他
                                </span>
                              </label>
                            </span>
                          </div>
                          {formErrors.type && (
                            <p className="error">{formErrors.type}</p>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <th>
                          <span class="inner">
                            会社名<span>必須</span>
                          </span>
                        </th>
                        <td class="validate-box">
                          <input
                            type="text"
                            name="companyName"
                            class="c-input"
                            size="60"
                            value={formData.companyName}
                            onChange={handleChange}
                          />
                          <br />
                          {formErrors.companyName && (
                            <p className="error">{formErrors.companyName}</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="inner">
                            部署名<span class="any">任意</span>
                          </span>
                        </th>
                        <td class="validate-box">
                          <input
                            type="text"
                            name="departmentName"
                            class="c-input"
                            size="60"
                            value={formData.departmentName}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="inner">
                            お名前<span>必須</span>
                          </span>
                        </th>
                        <td class="validate-box">
                          <input
                            type="text"
                            name="name"
                            class="c-input"
                            size="60"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          <br />
                          {formErrors.name && (
                            <p className="error">{formErrors.name}</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="inner">
                            Eメール<span>必須</span>
                          </span>
                        </th>
                        <td class="validate-box notice">
                          <input
                            type="text"
                            name="mail"
                            class="c-input"
                            size="60"
                            value={formData.mail}
                            onChange={handleChange}
                          />
                          <br />
                          {formErrors.mail && (
                            <p className="error">{formErrors.mail}</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="inner">
                            電話番号<span class="any">任意</span>
                          </span>
                        </th>
                        <td class="validate-box notice">
                          <input
                            type="text"
                            name="tel"
                            class="c-input"
                            size="60"
                            value={formData.tel}
                            onChange={handleChange}
                          />
                          <br />
                          {formErrors.type && (
                            <p className="error">{formErrors.type}</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th class="top">
                          <span class="inner">
                            お問い合わせ内容<span class="any">任意</span>
                          </span>
                        </th>
                        <td class="validate-box notice">
                          <textarea
                            name="details"
                            class="c-textarea"
                            cols="50"
                            rows="5"
                            value={formData.details}
                            onChange={handleChange}
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="p_contact-btn-area">
                  <input
                    type="submit"
                    name="submitConfirm"
                    value="入力内容の確認へ"
                    class="c-btn01 medium"
                    onClick={handleFormSubmit}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="c-bread-block outer-block">
          <div class="inner-block">
            <ul class="c-bread-list">
              <li>
                <a href="/" class="icon-home">
                  <img
                    decoding="async"
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon-home.svg"
                    alt=""
                  />
                </a>
              </li>
              <li>お問い合わせ</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="c-cta-block">
        <Link
          to="/contact"
          className="c-cta-block__item contact"
          onClick={toTop}
        >
          <div className="c-cta-block__ico">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/ico-contact-wt.svg"
              alt=""
            />
          </div>
          <div className="c-cta-block__main-ttl">お問い合わせ</div>
          <div className="c-cta-block__sub-ttl">Contact</div>
          <div className="c-cta-block__caption">
            DXコンサルティング、スマホアプリ開発、 システム開発に
            <br className="pc" />
            関する ご相談はこちらから
          </div>
          <div className="c-cta-block__arrow">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/arrow-wt-bt.svg"
              alt=""
            />
          </div>
        </Link>
        <Link
          to="/download"
          className="c-cta-block__item download"
          onClick={toTop}
        >
          <div className="c-cta-block__ico">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/ico-download-wt.svg"
              alt=""
            />
          </div>
          <div className="c-cta-block__main-ttl">資料ダウンロード</div>
          <div className="c-cta-block__sub-ttl">Download</div>
          <div className="c-cta-block__caption download">
            GeNEEの資料についてダウンロードはこちらから
          </div>
          <div className="c-cta-block__arrow">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/arrow-wt-bt.svg"
              alt=""
            />
          </div>
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
