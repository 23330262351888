import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import { Link } from "react-router-dom";

function Main() {
  const videoRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.log("Error playing video:", error);
      });
    }
  }, []);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mian1_box">
        <video
          className="pc"
          src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/video/mv.mp4?aaa"
          autoPlay
          muted
          loop
          playsInline
          poster="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/home/video.png"
        ></video>
      </div>

      <div className="main2_box">
        <div className="main2_box_inner">
          <div className="main2_box_inner_left">
            <ul>
              <li>
                <a
                  href="#home-vision"
                  className={`home-fixed-side-list__item ${
                    activeIndex === 0 ? "active" : ""
                  }`}
                  onClick={() => handleItemClick(0)}
                >
                  <div className="home-fixed-side-list__num">01</div>
                  <div className="home-fixed-side-list__ttl">
                    <div className="home-fixed-side-list__jp">ミッション</div>
                    <div className="home-fixed-side-list__en">Mission</div>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="#home-mission"
                  className={`home-fixed-side-list__item ${
                    activeIndex === 1 ? "active" : ""
                  }`}
                  onClick={() => handleItemClick(1)}
                >
                  <div className="home-fixed-side-list__num">02</div>
                  <div className="home-fixed-side-list__ttl">
                    <div className="home-fixed-side-list__jp">ビジョン</div>
                    <div className="home-fixed-side-list__en">Vision</div>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="#home-value"
                  className={`home-fixed-side-list__item ${
                    activeIndex === 2 ? "active" : ""
                  }`}
                  onClick={() => handleItemClick(2)}
                >
                  <div className="home-fixed-side-list__num">03</div>
                  <div className="home-fixed-side-list__ttl">
                    <div className="home-fixed-side-list__jp">提供価値</div>
                    <div className="home-fixed-side-list__en">Value</div>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="#home-company"
                  className={`home-fixed-side-list__item ${
                    activeIndex === 3 ? "active" : ""
                  }`}
                  onClick={() => handleItemClick(3)}
                >
                  <div className="home-fixed-side-list__num">04</div>
                  <div className="home-fixed-side-list__ttl">
                    <div className="home-fixed-side-list__jp">会社概要</div>
                    <div className="home-fixed-side-list__en">Company</div>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="#home-leadership"
                  className={`home-fixed-side-list__item ${
                    activeIndex === 4 ? "active" : ""
                  }`}
                  onClick={() => handleItemClick(4)}
                >
                  <div className="home-fixed-side-list__num">05</div>
                  <div className="home-fixed-side-list__ttl">
                    <div className="home-fixed-side-list__jp">経営陣</div>
                    <div className="home-fixed-side-list__en">
                      Leadership Team
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="main2_box_inner_right">
            <div id="home-vision" className="home-item01">
              <div className="home-item01-ttlbox">
                <div className="home-item01-ttlbox__num">01</div>
                <div className="home-item01-ttlbox__ttl">
                  <div className="home-item01-ttlbox__jp">ミッション</div>
                  <div className="home-item01-ttlbox__en">Mission</div>
                </div>
              </div>
              <div className="home-item01-ttl01">
                <div className="home-item01-ttl01__jp">
                  デジタル技術を駆使し、企業が抱える組織的課題・業務的課題を根本から解決する
                </div>
                <div className="home-item01-ttl01__en">
                  Solve organizational issues &amp; operational issues at the
                  root by making full use of DX &amp; latest technology.
                </div>
              </div>
              <div className="home-item01-cap01">
                弊社は、ビジネスマネージャー、テックエンジニア、UI/UXデザイナーによる三位一体型のプロジェクトチーム体制の下、セキュリティ強度の高いクラウドベースドなシステムの設計・開発、スケーラビリティを意識したスマホアプリの設計・開発、ユーザ行動中心設計に基づいた操作性に優れたUI/UXデザインを制作する開発会社です。お客様が描くシステムやスマホアプリの企画・構想に対し、GeNEEの強みとするプロジェクト牽引力×最先端のテクノロジーを意識した開発力×操作性やユーザビリティに優れたUI/UXデザイン力を乗算することで、半永続的に続く強固なシステムやスマホアプリを開発します。
              </div>
            </div>

            <div id="home-mission" className="home-item01 ptn01">
              <div className="home-item01-ttlbox ptn01">
                <div className="home-item01-ttlbox__num">02</div>
                <div className="home-item01-ttlbox__ttl">
                  <div className="home-item01-ttlbox__jp">ビジョン</div>
                  <div className="home-item01-ttlbox__en">Vision</div>
                </div>
              </div>
              <div className="home-item01-ttl01 ptn01">
                <div className="home-item01-ttl01__jp">
                  大切なお客様とともに、デジタルな現代社会をより効率的に、よりスマートなものへと変革させる
                </div>
                <div className="home-item01-ttl01__en">
                  Transforming modern society into more efficient &amp; smart
                  through business activities together with our customers
                </div>
              </div>
              <div className="home-item01-cap01 ptn01">
                ビジネス×技術×デザインの三位一体により、お客様のサービスを最大限にバックアップ。１社単独では実現ができないサービスやアイデアの形をGeNEE社がお客様とともに伴走し、社会全体に対し、価値あるアップデートを図って参ります。
              </div>
            </div>

            <div id="home-value" className="home-item01">
              <div className="home-item01-ttlbox">
                <div className="home-item01-ttlbox__num">03</div>
                <div className="home-item01-ttlbox__ttl">
                  <div className="home-item01-ttlbox__jp">提供価値</div>
                  <div className="home-item01-ttlbox__en">Value</div>
                </div>
              </div>
              <div className="home-value-flex">
                <div className="home-item01-value-box">
                  <div className="home-item01-value-box__item">
                    <div className="home-item01-value-box__ttl">お客様第一</div>
                    <div className="home-item01-value-box__en">
                      Client First
                    </div>
                    <div className="home-item01-value-box__cap">
                      お客様の立場を意識した開発支援を行います
                    </div>
                  </div>
                  <div className="home-item01-value-box__item">
                    <div className="home-item01-value-box__ttl">
                      積極的な挑戦
                    </div>
                    <div className="home-item01-value-box__en">
                      Aggressive Try
                    </div>
                    <div className="home-item01-value-box__cap">
                      お客様とともに、技術的な側面から弊社も挑戦し続けます
                    </div>
                  </div>
                  <div className="home-item01-value-box__item">
                    <div className="home-item01-value-box__ttl">
                      最善を尽くす
                    </div>
                    <div className="home-item01-value-box__en">Do the Best</div>
                    <div className="home-item01-value-box__cap">
                      メンバー全員が各領域のプロフェッショナルとしての意識を持ち、何事にも全力で対応いたします
                    </div>
                  </div>
                </div>
                <div className="home-item01-value-img">
                  <img
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/home/home-item-value-img.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div id="home-company" className="home-item01 ptn01">
              <div className="home-item01-ttlbox ptn01">
                <div className="home-item01-ttlbox__num">04</div>
                <div className="home-item01-ttlbox__ttl">
                  <div className="home-item01-ttlbox__jp">会社概要</div>
                  <div className="home-item01-ttlbox__en">Company</div>
                </div>
              </div>
              <div className="home-item01-company-tablebox">
                <table className="home-item01-company-table">
                  <tbody>
                    <tr>
                      <th>企業名</th>
                      <td>
                        株式会社GeNEE
                        <br />
                        （英語社名：GeNEE Corporation）
                      </td>
                    </tr>
                    <tr>
                      <th>挨拶</th>
                      <td>
                        <a className="privacy-link ptn02" href="/">
                          社長挨拶はこちら
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <th>沿革</th>
                      <td>
                        <a className="privacy-link ptn02" href="/">
                          弊社沿革はこちら
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th>資本金等</th>
                      <td>100,000,000円</td>
                    </tr>
                    <tr>
                      <th>加盟団体</th>
                      <td>
                        一般社団法人蔵前工業会
                        <br />
                        一般社団法人シェアリングエコノミー協会
                      </td>
                    </tr>
                    <tr>
                      <th>取引先（一部）</th>
                      <td>
                        木下グループ
                        <br />
                        パナソニック・グループ
                        <br />
                        ベネッセ・グループ
                        <br />
                        三井物産グループ
                        <br />
                        楽天グループ
                        <br />
                        リクルート・グループ
                        <br />
                        ADKホールディングス
                        <br />
                        MUTOHホールディングス
                        <br />
                        SBCホールディングス
                        <br />
                        国立大学法人東京大学
                        <br />
                        国立大学法人東京工業大学
                        <br />
                        学校法人慶應義塾大学
                        <br />
                        学校法人北里研究所
                        <br />
                        学校法人法政大学
                        <br />
                        株式会社コロナ検査センター
                        <br />
                        株式会社木下工務店
                        <br />
                        株式会社木下不動産
                        <br />
                        株式会社甲南チケット
                        <br />
                        株式会社セレスポ
                        <br />
                        株式会社NaITO
                        <br />
                        株式会社ビデオリサーチ
                        <br />
                        株式会社ビックカメラ
                        <br />
                        株式会社LOCUS
                        <br />
                        オーデリック株式会社
                        <br />
                        鈴中工業株式会社
                        <br />
                        湘南美容外科
                        <br />
                        ターキッシュ・エア
                        <br />
                        太陽誘電株式会社
                        <br />
                        竹島開発株式会社
                        <br />
                        長野計器株式会社
                        <br />
                        北王流通株式会社
                        <br />
                        武藤工業株式会社
                        <br />
                        官公庁含む地方自治体
                        <br />
                        その他大企業
                        <br />
                        その他スタートアップ企業等　多数
                      </td>
                    </tr>
                    <tr>
                      <th>取引銀行</th>
                      <td>
                        三井住友銀行 六本木支店
                        <br />
                        三菱UFJ銀行 六本木支店
                        <br />
                        みずほ銀行 六本木支店・大塚支店
                        <br />
                        りそな銀行 赤坂支店
                        <br />
                        楽天銀行
                        <br />
                        PayPay銀行
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <th>顧問弁護士</th>
                      <td>
                        ベリーベスト法律事務所
                        <br />
                        折田 忠仁 弁護士
                        <br />
                        的場 理依 弁護士
                      </td>
                    </tr>
                    <tr>
                      <th>顧問税理士</th>
                      <td>
                        ヒット税理士法人
                        <br />林 一樹 税理士（国税庁OB）
                      </td>
                    </tr>
                    <tr>
                      <th>顧問会計士</th>
                      <td>
                        エクセライク会計事務所
                        <br />
                        伊藤温志 公認会計士
                      </td>
                    </tr>
                    <tr>
                      <th>東京オフィス</th>
                      <td>
                        〒106-0032 <br />
                        東京都港区六本木1-4-5森ビルアークヒルズサウスタワー
                        <div className="home-item01-company-map">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.458826137799!2d139.7368056764479!3d35.66570277259216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b9b28fb24d1%3A0x6d9cfd9c482357bd!2z44Ki44O844Kv44OS44Or44K6IOOCteOCpuOCueOCv-ODr-ODvA!5e0!3m2!1sja!2sjp!4v1712748637211!5m2!1sja!2sjp"
                            width="600"
                            height="450"
                            style={{ border: "0" }}
                            allowfullscreen=""
                            loading="lazy"
                            title="1"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>大阪オフィス</th>
                      <td>
                        〒530-0011 <br />
                        大阪府大阪市北区大深町1-1 ヨドバシ梅田タワー
                        <div className="home-item01-company-map">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.026689023116!2d135.49360307641325!3d34.70450677291812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e68e08061279%3A0x6f931ffaac6d5474!2z44CSNTMwLTAwMTEg5aSn6Ziq5bqc5aSn6Ziq5biC5YyX5Yy65aSn5rex55S677yR4oiS77yRIOODqOODieODkOOCt-aiheeUsOOCv-ODr-ODvA!5e0!3m2!1sja!2sjp!4v1712748664550!5m2!1sja!2sjp"
                            width="600"
                            height="450"
                            style={{ border: "0" }}
                            allowfullscreen=""
                            loading="lazy"
                            title="2"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>名古屋オフィス</th>
                      <td>
                        〒450-6001 <br />
                        愛知県名古屋市中村区名駅１丁目１−４ JRセントラルタワーズ
                        <div className="home-item01-company-map">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.3882272339456!2d136.87964361187764!3d35.171875172642295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003771e8c676b71%3A0x2e9ed74ab2c12142!2zSlLjgrvjg7Pjg4jjg6njg6vjgr_jg6_jg7zjgro!5e0!3m2!1sja!2sjp!4v1727837123546!5m2!1sja!2sjp"
                            width="600"
                            height="450"
                            style={{ border: "0" }}
                            allowfullscreen=""
                            loading="lazy"
                            title="3"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>福岡オフィス</th>
                      <td>
                        〒812-0011 <br />
                        福岡県福岡市博多区博多駅前1-11-13 マーケット博多ビル
                        <div className="home-item01-company-map">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.585247687746!2d130.41687946148986!3d33.590116391879064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3541911d025badbd%3A0xf17e6fdaf53340dd!2z5Y2a5aSa44Kv44Oq44K544Oe44K544Oe44O844Kx44OD44OI!5e0!3m2!1sja!2sjp!4v1715684816932!5m2!1sja!2sjp"
                            width="600"
                            height="450"
                            style={{ border: "0" }}
                            allowfullscreen=""
                            loading="lazy"
                            title="4"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>北海道オフィス</th>
                      <td>
                        〒060-0042 <br />
                        北海道札幌市中央区大通西1-14-2桂和大通ビル
                        <div className="home-item01-company-map">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d728.7735515684308!2d141.35579956963483!3d43.06048617805198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f0b2982b4a374db%3A0x1db65c48584f6720!2z44CSMDYwLTAwNDIg5YyX5rW36YGT5pyt5bmM5biC5Lit5aSu5Yy65aSn6YCa6KW_77yR5LiB55uu77yR77yU4oiS77yS!5e0!3m2!1sja!2sjp!4v1717498395645!5m2!1sja!2sjp"
                            width="600"
                            height="450"
                            style={{ border: "0" }}
                            allowfullscreen=""
                            loading="lazy"
                            title="5"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div id="home-leadership" className="home-item01">
              <div className="home-item01-ttlbox">
                <div className="home-item01-ttlbox__num">05</div>
                <div className="home-item01-ttlbox__ttl">
                  <div className="home-item01-ttlbox__jp">経営陣</div>
                  <div className="home-item01-ttlbox__en">Leadership Team</div>
                </div>
              </div>

              <div className="home-leader-imgbox">
                <div className="home-leader-pointer" data-slide="0">
                  <span></span>
                </div>
                <div className="home-leader-pointer active" data-slide="1">
                  <span></span>
                </div>
                <div className="home-leader-pointer" data-slide="2">
                  <span></span>
                </div>
              </div>
              <div className="home-leader-slider">
                <div className="home-leader-slider__box js-leader-slider slick-initialized slick-slider">
                  <div className="slick-list draggable">
                    <div
                      className="slick-track"
                      style={{
                        width: "4410px",
                      }}
                    >
                      <div
                        className="home-leader-slider__item slick-slide slick-current slick-active"
                        data-slick-index="1"
                        aria-hidden="false"
                        style={{ width: "630px" }}
                        tabindex="0"
                      >
                        <div className="home-leader-slider__ttlbox">
                          <div className="home-leader-slider__subttl">
                            代表取締役兼創業者
                          </div>
                          <div className="home-leader-slider__mainttl">
                            日向野 卓也
                          </div>
                        </div>
                        <div className="home-leader-slider__contbox">
                          東京工業大学環境社会理工学院、慶應義塾大学大学院・慶應義塾大学ビジネススクールMBA（経営学修士取得）卒業。国内最大手IT企業の株式会社NTTデータなどでエンタープライズ（大手法人）領域の事業開発・事業企画等に従事。スタンフォード大学への海外研修を経て、株式会社GeNEEを法人設立。
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-leader-list">
                <div className="home-leader-list__item">
                  <div className="home-leader-list__left">
                    <div className="home-leader-list__ttlbox">
                      <div className="home-leader-list__subttl">
                        業務執行役員
                      </div>
                      <div className="home-leader-list__mainttl">張 思提</div>
                    </div>
                  </div>
                  <div className="home-leader-list__right">
                    早稲田大学創造理工学部、早稲田大学大学院創造理工学研究科卒業。大手インターネット広告事業会社、広告代理店にてUI/UXデザイン業務、Web/SNSマーケティング業務に従事。Web系のフリーランスを経験した後、株式会社GeNEEに参画。
                  </div>
                </div>
                <div className="home-leader-list__item">
                  <div className="home-leader-list__left">
                    <div className="home-leader-list__ttlbox">
                      <div className="home-leader-list__subttl">
                        業務執行役員
                      </div>
                      <div className="home-leader-list__mainttl">平田 拓哉</div>
                    </div>
                  </div>
                  <div className="home-leader-list__right">
                    早稲田大学大学院ファイナンス研究科卒業。MSc取得（ファイナンス学修士）。外資系コンサルティングファームAccentureで大手製造業界/エンタープライズ向けのビジネス・プロセス・サービス等の戦略設計及びITコンサルティング業務の実務経験を持つ。
                  </div>
                </div>
                <div className="home-leader-list__item">
                  <div className="home-leader-list__left">
                    <div className="home-leader-list__ttlbox">
                      <div className="home-leader-list__subttl">
                        業務執行役員
                      </div>
                      <div className="home-leader-list__mainttl">宮内 秀世</div>
                    </div>
                  </div>
                  <div className="home-leader-list__right">
                    慶應義塾大学大学院システムデザイン・マネジメント研究科卒業。大手コンサルティング会社にて、プロジェクトマネジメント支援を専門分野とし、ＩＴ企業や製造業を中心に多数のプロジェクトに従事。米国PMI認定PMI-ACPを保持者。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main3_box">
        <div className="main3_box_inner">
          <div className="main3_box_inner_top">
            <div className="main3_top_inner">
              <div className="main3-home-common-ttl-block__ttl-jp">実績</div>
              <div className="main3-home-common-ttl-block__ttl-en">Works</div>
            </div>
          </div>
          <div className="home-works-block__list">
            <ul className="c-item-list01">
              <li>
                <div className="c-item-list01__img">
                  <img
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2022/12/GeNEE_学習管理システム（LMS）の開発.jpg"
                    alt=""
                  />
                </div>
                <div className="c-item-list01__cont">
                  <div className="c-item-list01__tagbox">
                    <ul className="c-tag-list01">
                      <li>
                        <div className="c-tag-list01__item">DX推進 </div>
                      </li>
                    </ul>
                  </div>
                  <div className="c-item-list01__subttl-box">
                    学校法人法政大学{" "}
                  </div>
                  <div className="c-item-list01__ttl-box">
                    学習管理システム（LMS）の開発{" "}
                  </div>
                </div>
              </li>
              <li>
                <div className="c-item-list01__img">
                  <img
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2021/11/GeNEE_IOデータHWを用いたソフトウェア開発-scaled.jpg"
                    alt=""
                  />
                </div>
                <div className="c-item-list01__cont">
                  <div className="c-item-list01__tagbox">
                    <ul className="c-tag-list01">
                      <li>
                        <div className="c-tag-list01__item">DX推進 </div>
                      </li>
                    </ul>
                  </div>
                  <div className="c-item-list01__subttl-box">大手医療機関 </div>
                  <div className="c-item-list01__ttl-box">
                    HWを活用した検査試験確認業務ソフトウェア開発{" "}
                  </div>
                </div>
              </li>
              <li>
                <div className="c-item-list01__img">
                  <img
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2022/02/GeNEE_FreaK_アプリ開発-1-scaled.jpg"
                    alt=""
                  />
                </div>
                <div className="c-item-list01__cont">
                  <div className="c-item-list01__tagbox">
                    <ul className="c-tag-list01">
                      <li>
                        <div className="c-tag-list01__item">
                          スマホアプリ開発{" "}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="c-item-list01__subttl-box">
                    合同会社FreaK{" "}
                  </div>
                  <div className="c-item-list01__ttl-box">
                    PUSH通知課金型アプリの開発{" "}
                  </div>
                </div>
              </li>
              <li>
                <div className="c-item-list01__img">
                  <img
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2021/11/株式会社GeNEE_システム開発_実績4-scaled.jpg"
                    alt=""
                  />
                </div>
                <div className="c-item-list01__cont">
                  <div className="c-item-list01__tagbox">
                    <ul className="c-tag-list01">
                      <li>
                        <div className="c-tag-list01__item">システム開発 </div>
                      </li>
                    </ul>
                  </div>
                  <div className="c-item-list01__subttl-box">
                    国立大学法人東京大学{" "}
                  </div>
                  <div className="c-item-list01__ttl-box">
                    動画配信管理システムの開発{" "}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="main4_box">
        <div className="main4_box_inner">
          <div className="main3_box_inner_top">
            <div className="main3_top_inner">
              <div className="main3-home-common-ttl-block__ttl-jp">
                お知らせ
              </div>
              <div className="main3-home-common-ttl-block__ttl-en">News</div>
            </div>
          </div>
          <div className="home-works-block__list">
            <ul className="c-item-list01">
              <li>
                <div className="c-item-list01__img">
                  <img
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/09/『アプリ開発発注担当者必見！　新規プロダクト事業を成功させる100の鉄則』アイキャッチ画像-1.png"
                    alt=""
                  />
                </div>
                <div className="c-item-list01__cont">
                  <div className="c-item-list01__tagbox">
                    <ul className="c-tag-list01">
                      <li>
                        <div className="c-tag-list01__item">ﾌﾟﾚｽﾘﾘｰｽ </div>
                      </li>
                    </ul>
                  </div>
                  <div className="c-item-list01__ttl-box">
                    『アプリ開発発注担当者必見！　新規プロダクト事業を成功させる100の鉄則』を商業出版しました
                  </div>
                </div>
              </li>
              <li>
                <div className="c-item-list01__img">
                  <img
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/07/書籍.png"
                    alt=""
                  />
                </div>
                <div className="c-item-list01__cont">
                  <div className="c-item-list01__tagbox">
                    <ul className="c-tag-list01">
                      <li>
                        <div className="c-tag-list01__item">ﾌﾟﾚｽﾘﾘｰｽ </div>
                      </li>
                    </ul>
                  </div>
                  <div className="c-item-list01__ttl-box">
                    弊社技術書籍（在庫管理システム）の韓国版を商業出版しました
                  </div>
                </div>
              </li>
              <li>
                <div className="c-item-list01__img">
                  <img
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/03/GeNEE_Youtube動画のサムネイル.png"
                    alt=""
                  />
                </div>
                <div className="c-item-list01__cont">
                  <div className="c-item-list01__tagbox">
                    <ul className="c-tag-list01">
                      <li>
                        <div className="c-tag-list01__item">ニュース </div>
                      </li>
                    </ul>
                  </div>
                  <div className="c-item-list01__ttl-box">
                    『GeNEEで解決！システム・アプリ開発ch』が開始しました
                  </div>
                </div>
              </li>
              <li>
                <div className="c-item-list01__img">
                  <img
                    src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/02/KYOUSOUプロジェクトPR_タイトル画像B案.jpg"
                    alt=""
                  />
                </div>
                <div className="c-item-list01__cont">
                  <div className="c-item-list01__tagbox">
                    <ul className="c-tag-list01">
                      <li>
                        <div className="c-tag-list01__item">ﾌﾟﾚｽﾘﾘｰｽ </div>
                      </li>
                    </ul>
                  </div>
                  <div className="c-item-list01__ttl-box">
                    IT顧問/技術顧問に関するプレスリリースを配信しました
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="main4_box">
        <div className="main4_box_inner">
          <div className="main3_box_inner_top">
            <div className="main3_top_inner">
              <div className="main3-home-common-ttl-block__ttl-jp">
                メディア
              </div>
              <div className="main3-home-common-ttl-block__ttl-en">Media</div>
            </div>
          </div>
          <div className="c-blog-list03">
            <ul className="c-blog-list03">
              <li>
                <div className="c-blog-list03__item">
                  <a href="/" className="c-blog-list03__tag">
                    アプリ開発メディア
                  </a>
                  <a href="/" className="c-blog-list03__tag">
                    テック全般メディア{" "}
                  </a>
                  <a href="/" className="c-blog-list03__tag">
                    ビジネス調査メディア{" "}
                  </a>
                  <div className="c-blog-list03__time">
                    公開日:2024.11.08
                    <br />
                    更新日:2024.11.08{" "}
                  </div>
                  <a href="/" className="c-blog-list03__link">
                    <div className="c-blog-list03__title">
                      スーパーアプリが実現する新たな生活インフラ
                    </div>
                    <div className="c-blog-list03__cont">
                      スマートフォンの普及とともに、人々の生活はますますデジタル化が進んでいます。その中で、注目を集めているのが「ス…
                    </div>
                  </a>
                  <ul className="p_blog-news c-icon-list">
                    <li>
                      <a
                        href="/"
                        rel="nofollow noopener"
                        className="c-icon-list__item"
                      >
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/twitter.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/FaceBook.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/hatena.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/line.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon_unlike.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <span className="count-box wp_ulike_counter_up">+5</span>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="c-blog-list03__item">
                  <a href="/" className="c-blog-list03__tag">
                    システム開発メディア
                  </a>
                  <a href="/" className="c-blog-list03__tag">
                    テック全般メディア
                  </a>
                  <a href="/" className="c-blog-list03__tag">
                    セキュリティメディア
                  </a>
                  <div className="c-blog-list03__time">
                    公開日:2024.11.06
                    <br />
                    更新日:2024.11.06
                  </div>
                  <a href="/" className="c-blog-list03__link">
                    <div className="c-blog-list03__title">
                      システム監査がもたらす安心：バグや不具合を未然に防ぐ方法
                    </div>
                    <div className="c-blog-list03__cont">
                      企業のITシステムは、日々複雑さを増し、リスクも多様化しています。リスク多様化の課題に対し、システム監査はバグ…
                    </div>
                  </a>
                  <ul className="p_blog-news c-icon-list">
                    <li>
                      <a
                        href="/"
                        rel="nofollow noopener"
                        className="c-icon-list__item"
                      >
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/twitter.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/FaceBook.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/hatena.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/line.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon_unlike.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <span className="count-box wp_ulike_counter_up">+7</span>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="c-blog-list03__item">
                  <a href="/" className="c-blog-list03__tag">
                    アプリ開発メディア
                  </a>
                  <a href="/" className="c-blog-list03__tag">
                    ビジネス調査メディア
                  </a>
                  <a href="/" className="c-blog-list03__tag">
                    MVPメディア
                  </a>
                  <div className="c-blog-list03__time">
                    公開日:2024.10.29
                    <br />
                    更新日:2024.10.29
                  </div>
                  <a href="/" className="c-blog-list03__link">
                    <div className="c-blog-list03__title">
                      スタートアップを加速するMVP開発の重要性
                    </div>
                    <div className="c-blog-list03__cont">
                      スタートアップの成功には、限られたリソースを最大限に活用し、迅速に市場に適応することが求められます。そのための…{" "}
                    </div>
                  </a>
                  <ul className="p_blog-news c-icon-list">
                    <li>
                      <a
                        href="/"
                        rel="nofollow noopener"
                        className="c-icon-list__item"
                      >
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/twitter.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/FaceBook.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/hatena.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/line.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon_unlike.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <span className="count-box wp_ulike_counter_up">+14</span>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="c-blog-list03__item">
                  <a href="/" className="c-blog-list03__tag">
                    アプリ開発メディア
                  </a>
                  <a href="/" className="c-blog-list03__tag">
                    テック全般メディア
                  </a>
                  <a href="/" className="c-blog-list03__tag">
                    UI/UXデザインメディア
                  </a>
                  <div className="c-blog-list03__time">
                    公開日:2024.10.25
                    <br />
                    更新日:2024.10.25
                  </div>
                  <a href="/" className="c-blog-list03__link">
                    <div className="c-blog-list03__title">
                      iOSとAndroid対応のクロスプラットフォームアプリ開発で成功を掴む
                    </div>
                    <div className="c-blog-list03__cont">
                      クロスプラットフォームアプリ開発は、iOSとAndroidなど異なるOS上で同一のコードベースからアプリを動作…{" "}
                    </div>
                  </a>
                  <ul className="p_blog-news c-icon-list">
                    <li>
                      <a
                        href="/"
                        rel="nofollow noopener"
                        className="c-icon-list__item"
                      >
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/twitter.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/FaceBook.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/hatena.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/line.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/" className="c-icon-list__item">
                        <div className="c-icon-list__img">
                          <img
                            src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/icon_unlike.svg"
                            alt=""
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <span className="count-box wp_ulike_counter_up">+16</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="main4_box">
        <div className="main4_box_inner">
          <div className="main3_box_inner_top">
            <div className="main3_top_inner">
              <div className="main3-home-common-ttl-block__ttl-jp">
                開催ウェビナー
              </div>
              <div className="main3-home-common-ttl-block__ttl-en">Webinar</div>
            </div>
          </div>
          <div className="home-webinar-block__list">
            <ul className="home-webinar-list">
              <li>
                <span className="home-webinar-list__none">
                  coming soon
                  <br />
                  ～直近のウェビナーは開催準備中です～
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="main4_box">
        <div className="main4_box_inner">
          <div className="main3_box_inner_top">
            <div className="main3_top_inner">
              <div className="main3-home-common-ttl-block__ttl-jp">
                過去ウェビナー
              </div>
              <div className="main3-home-common-ttl-block__ttl-en">
                Webinar Documents
              </div>
            </div>
          </div>
          <div className="main6-home-webinar-block__list">
            <ul className="home-webinar-list document">
              <li>
                <div className="home-webinar-list__date-box">
                  <div className="home-webinar-list__date-cont">
                    <div className="home-webinar-list__date-year">2024年</div>
                    <div className="home-webinar-list__date-txt">
                      08/14
                      <span className="home-webinar-list__date-week">水</span>
                    </div>
                    <div className="home-webinar-list__date-time">
                      18:00 - 19:00{" "}
                    </div>
                  </div>
                </div>
                <div className="home-webinar-list__cont-box">
                  <div className="home-webinar-list__img-box">
                    <div className="home-webinar-list__img">
                      <img
                        src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/09/生産管理システム・MESに関するセミナー.jpg"
                        alt="高度化する生産管理システムのMES。DX化が進む昨今において求められる具体的な要素"
                      />
                    </div>
                  </div>
                  <div className="home-webinar-list__ttl-box">
                    <div className="home-webinar-list__ttl-txt">
                      高度化する生産管理システムのMES。DX化が進む昨今において求められる具体的な要素{" "}
                    </div>

                    <div className="home-webinar-list__ttl-desc">
                      大手企業を中心として、DX化の動きが加速しており、昨今では中堅企業～中小企業までその動きが広がりつつあります。多くの企業が自社システムの更なる高度化や見直し、刷新プロジェクトを進める中、なかなか上手くいかず、途中で断念、頓挫してしまうという話も聞こえてきます。しかしながら、企業の根幹を支える業務管理システムのメンテナンスやリプレイスを怠ると、他のデジタルツールとの適切なデータ連携が上手くいかず、全社的データが分散してしまい、経営管理や経営分析に大きな問題を抱えることが往々にしてございます。現在においても、企業規模関係なく、そのような根深い問題を放置した状態という企業は多く存在しており、弊社の方にも「今回の開発プロジェクトでこれまでの問題や課題をなんとか解決したい。」といったお問い合わせが多数寄せられています。今回のウェビナーではMESを含む最先端の生産管理システムについてご説明したいと思います。{" "}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="home-webinar-list__date-box">
                  <div className="home-webinar-list__date-cont">
                    <div className="home-webinar-list__date-year">2024年</div>
                    <div className="home-webinar-list__date-txt">
                      05/29
                      <span className="home-webinar-list__date-week">水</span>
                    </div>
                    <div className="home-webinar-list__date-time">
                      18:00 - 19:00{" "}
                    </div>
                  </div>
                </div>
                <div className="home-webinar-list__cont-box">
                  <div className="home-webinar-list__img-box">
                    <div className="home-webinar-list__img">
                      <img
                        src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/06/GeNEE_システムのサイロ化に関するセミナー研修.jpg"
                        alt="高度化する生産管理システムのMES。DX化が進む昨今において求められる具体的な要素"
                      />
                    </div>
                  </div>
                  <div className="home-webinar-list__ttl-box">
                    <div className="home-webinar-list__ttl-txt">
                      基幹システム・業務システムのサイロ化が招く潜在的リスクとは？早期対処・早期改善が重要な理由
                    </div>

                    <div className="home-webinar-list__ttl-desc">
                      DXという言葉が騒がれてから5年以上の月日が経ちました。多くの企業がDXを進める中、大きな壁の一つとして、「システムのサイロ化」と呼ばれるものがあります。「サイロ化」という言葉はあまり聞き馴染みがないかもしれませんが、企業などの組織が日常業務に利用するシステムにおいて、部署や部門を跨いで別システムとのデータ連携などが行えないがために、全社的なデータ管理、リアルタイムな情報連携などが行えない状態を意味します。{" "}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="home-webinar-list__date-box">
                  <div className="home-webinar-list__date-cont">
                    <div className="home-webinar-list__date-year">2024年</div>
                    <div className="home-webinar-list__date-txt">
                      03/08
                      <span className="home-webinar-list__date-week">金</span>
                    </div>
                    <div className="home-webinar-list__date-time">
                      18:00 - 19:30{" "}
                    </div>
                  </div>
                </div>
                <div className="home-webinar-list__cont-box">
                  <div className="home-webinar-list__img-box">
                    <div className="home-webinar-list__img">
                      <img
                        src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/03/GeNEE_Claude2に関するウェビナー.jpg"
                        alt="高度化する生産管理システムのMES。DX化が進む昨今において求められる具体的な要素"
                      />
                    </div>
                  </div>
                  <div className="home-webinar-list__ttl-box">
                    <div className="home-webinar-list__ttl-txt">
                      Claude3やChat-GPTを自社システム、自社アプリにどのように適用すべきか
                    </div>

                    <div className="home-webinar-list__ttl-desc">
                      2022年から凄まじい速度で広がりを見せる生成AI。特にChatGPT、Claude2については人間に近い自然な会話力が魅力で導入されている企業様も増えてきております。今回のウェビナーでは、ChatGPTの対抗馬ともいえるClaude2についてもフォーカスを当て、企業がどのようにして生成AIを使いこなし、業務効率化やAIによる自動化を加速させることができるのか、又売上を伸ばすことができるのか、その可能性について具体的に解説していきます。{" "}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="home-webinar-list__date-box">
                  <div className="home-webinar-list__date-cont">
                    <div className="home-webinar-list__date-year">2023年</div>
                    <div className="home-webinar-list__date-txt">
                      12/20
                      <span className="home-webinar-list__date-week">水</span>
                    </div>
                    <div className="home-webinar-list__date-time">
                      16:00 - 17:00
                    </div>
                  </div>
                </div>
                <div className="home-webinar-list__cont-box">
                  <div className="home-webinar-list__img-box">
                    <div className="home-webinar-list__img">
                      <img
                        src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2024/01/GeNEE_Chat-GPTと業務システムのセミナー.jpg"
                        alt="高度化する生産管理システムのMES。DX化が進む昨今において求められる具体的な要素"
                      />
                    </div>
                  </div>
                  <div className="home-webinar-list__ttl-box">
                    <div className="home-webinar-list__ttl-txt">
                      生成AI・Chat-GPT /
                      チャットジーピーティーと業務システムの未来。ビジネス革新は始まっている。
                    </div>

                    <div className="home-webinar-list__ttl-desc">
                      2024年現在、急速に広がりを見せている生成AI技術ですが、その中でも最も注目されているのがオープンAI社が開発したChat-GPT4系です。Chat-GPTについては少しずつ世間の認知が広がっていますが、エンタープライズ領域に目を向けるとまだ十分な利活用が進んでいないというのが実情です。今回のウェビナーでは、話題を集めるChat-GPTを中心として、隣接する業務管理システムとどのように連携すべきか、また各種デジタルツール、システムを連携させる事で自社にどのようなメリットをもたらすことが出来るのか、企業の業務効率化に頭を悩ませている方に向けて解説します。{" "}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="main4_box">
        <div className="main4_box_inner">
          <div className="main3_box_inner_top">
            <div className="main3_top_inner">
              <div className="main3-home-common-ttl-block__ttl-jp">
                お客様の声
              </div>
              <div className="main3-home-common-ttl-block__ttl-en">
                Client Voice
              </div>
            </div>
          </div>

          <div className="home-voice-block__list">
            <ul className="c-voice-list02">
              <li>
                <div className="c-voice-list02__img">
                  <div className="c-voice-list02__img-box">
                    <img
                      src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2023/05/GeNEEと法政大学【お客様の声１】.jpg"
                      alt="「コンサルティング力」、「技術力」、「迅速さと誠実さ」があるからこそスムーズに開発プロジェクトが進行したのだと思います。"
                    />
                  </div>
                </div>
                <div className="c-voice-list02__cont">
                  <div className="c-voice-list02__ttl">
                    「コンサルティング力」、「技術力」、「迅速さと誠実さ」があるからこそスムーズに開発プロジェクトが進行したのだと思います。{" "}
                  </div>

                  <table className="c-voice-table">
                    <tbody>
                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">
                            会社名
                          </div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            学校法人法政大学
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">
                            担当者名
                          </div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            井上 様
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">
                            従業員規模
                          </div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            約35,000名（総学生数）
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">年商</div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            約725億円（2022年度）
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>
                <div className="c-voice-list02__img">
                  <div className="c-voice-list02__img-box">
                    <img
                      src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2023/05/GeNEEと甲南チケット【お客様の声】.png"
                      alt="GeNEEさんはシステムよろず相談窓口です。『ITトータルパートナー』として、これからも色々とご相談をしたいと思っております。"
                    />
                  </div>
                </div>
                <div className="c-voice-list02__cont">
                  <div className="c-voice-list02__ttl">
                    GeNEEさんはシステムよろず相談窓口です。『ITトータルパートナー』として、これからも色々とご相談をしたいと思っております。{" "}
                  </div>

                  <table className="c-voice-table">
                    <tbody>
                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">
                            会社名
                          </div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            株式会社甲南チケット
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">
                            担当者名
                          </div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            藤巻 様
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">
                            従業員規模
                          </div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            約600名（グループ全体）
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">年商</div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            約585億円（2023年度）
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>
                <div className="c-voice-list02__img">
                  <div className="c-voice-list02__img-box">
                    <img
                      src="https://genee.jp/GLPtxA8kdBEJ/wp-content/uploads/2023/05/PCR検査センター.png"
                      alt="シビアな開発期間の中でも、最後まで全力で支援してくれるシステム開発会社です。"
                    />
                  </div>
                </div>
                <div className="c-voice-list02__cont">
                  <div className="c-voice-list02__ttl">
                    シビアな開発期間の中でも、最後まで全力で支援してくれるシステム開発会社です。{" "}
                  </div>

                  <table className="c-voice-table">
                    <tbody>
                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">
                            会社名
                          </div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            株式会社木下グループ
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">
                            担当者名
                          </div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            高野 様 / 坂本 様
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">
                            従業員規模
                          </div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            約8,500名（グループ全体）
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="c-voice-list02__client-ttl">年商</div>
                        </th>
                        <td>
                          <div className="c-voice-list02__client-cont">
                            約2,000億円（2022年度）
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="c-cta-block">
        <Link
          to="/contact"
          className="c-cta-block__item contact"
          onClick={toTop}
        >
          <div className="c-cta-block__ico">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/ico-contact-wt.svg"
              alt=""
            />
          </div>
          <div className="c-cta-block__main-ttl">お問い合わせ</div>
          <div className="c-cta-block__sub-ttl">Contact</div>
          <div className="c-cta-block__caption">
            DXコンサルティング、スマホアプリ開発、 システム開発に
            <br className="pc" />
            関する ご相談はこちらから
          </div>
          <div className="c-cta-block__arrow">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/arrow-wt-bt.svg"
              alt=""
            />
          </div>
        </Link>
        <Link
          to="/download"
          className="c-cta-block__item download"
          onClick={toTop}
        >
          <div className="c-cta-block__ico">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/ico-download-wt.svg"
              alt=""
            />
          </div>
          <div className="c-cta-block__main-ttl">資料ダウンロード</div>
          <div className="c-cta-block__sub-ttl">Download</div>
          <div className="c-cta-block__caption download">
            GeNEEの資料についてダウンロードはこちらから
          </div>
          <div className="c-cta-block__arrow">
            <img
              src="https://genee.jp/GLPtxA8kdBEJ/wp-content/themes/genee/img/common/arrow-wt-bt.svg"
              alt=""
            />
          </div>
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
